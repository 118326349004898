.revive-image-grid {
  position: relative;
  height: 90vh;
}

.revive-image-grid li {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%;
}

.revive-image-grid li img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: 50% 50%;
}

.revive-image-grid li:nth-child(2n -1) {
  width: 33.33%;
  height: 33.33%;
}

.revive-image-grid li:nth-child(3n - 1) {
  left: 33.33%;
  width: 33.33%;
  height: 100%;
}

.revive-image-grid li:nth-child(4n - 1) {
  left: 66.66%;
  width: 33.33%;
  height: 33.33%;
}

.revive-image-grid li:nth-child(5n - 1) {
  width: 33.33%;
  height: 33.33%;
  top: 33.33%;
  left: 0;
}

.revive-image-grid li:nth-child(5n) {
  width: 33.33%;
  height: 33.33%;
  top: 33.33%;
  left: 66.66%;
}

.revive-image-grid li:nth-child(6n) {
  width: 33.33%;
  height: 33.33%;
  top: 33.33%;
  left: 66.66%;
}

.revive-image-grid li:nth-child(7n) {
  width: 16.66%;
  height: 33.33%;
  top: 66.66%;
  left: 0;
}

.revive-image-grid li:nth-child(8n) {
  width: 16.66%;
  height: 33.33%;
  top: 66.66%;
  left: 16.66%;
}

.revive-image-grid li:nth-child(9n) {
  width: 16.66%;
  height: 33.33%;
  top: 66.66%;
  left: 66.66%;
}

.revive-image-grid li:nth-child(10n) {
  width: 16.66%;
  height: 33.33%;
  top: 66.66%;
  left: 83.32%;
}

/* Mobile */
@media (max-width: 500px) {
  .revive-image-grid {
    position: static;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  .revive-image-grid li {
    position: static;
    width: 100% !important;
    height: 33vh !important;
  }
}
