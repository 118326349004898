header {
  background-color: var(--greenColor);
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  padding: 0 var(--quadPadding);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  box-shadow: 0px 8px 16px rgba(1, 1, 1, 0.1);
}

header ul {
  display: flex;
  gap: var(--doublePadding);
}

header ul li {
  font-size: var(--reviveBody);
  transition: all 0.3s ease;
}

header ul li:hover {
  cursor: pointer;
  opacity: 0.9;
  transform: translateY(-1px);
}

header .revive-ig {
  margin: 0 var(--standardPadding) 0 auto;
  display: flex;
  align-items: center;
  transition: all 0.4s ease;
}

header .revive-ig:hover {
  transform: translateY(-2px);
  opacity: 0.9;
}

header .revive-ig svg {
  height: 2.4rem;
  width: 2.4rem;
}

header .revive-ig svg path {
  fill: #fff;
}

header .revive-primary-btn {
  margin: 0;
  font-size: var(--reviveH6);
}

.mobile-menu-btn svg {
  height: 2rem;
  width: 2rem;
}

.mobile-menu-btn svg path {
  fill: #000;
}

/* Mobile menu */
.mobile-menu {
  position: fixed;
  top: var(--headerHeight);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--headerHeight));
  background-color: var(--greenColor);
  padding: var(--doublePadding) var(--quadPadding);
  z-index: 9999;
  transition: all 0.4s ease;
  transform-origin: 50% 0;
}

.mobile-menu.collapsed {
  transform: translateX(100vw);
  opacity: 0;
}

.mobile-menu ul {
  flex-direction: column;
  margin: var(--doublePadding);
  gap: var(--quadPadding);
}

/* Mobile */
@media screen and (max-width: 900px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .mobile-menu-btn {
    margin: 0 0 0 auto;
  }
}
