.revive-banner {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 var(--pageMargin);
  color: #fff;
  gap: var(--standardPadding);
  width: 100%;
}

.banner-content h2 {
  text-decoration: none;
}

.banner-content svg {
  max-width: 100%;
}

/* Mobile */
@media screen and (max-width: 900px) and (orientation: portrait) {
  .banner-content svg {
    width: 70%;
  }

  .revive-banner {
    width: 100%;
    height: 70vh;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .banner-content svg {
    width: 40%;
  }

  .revive-banner {
    width: 100%;
    height: 100vh;
  }
}
