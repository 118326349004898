.revive-text-container {
  perspective: 500px;
  background-color: var(--pageColor);
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-container-content {
  padding: var(--quadPadding) var(--pageMargin);
}

.text-container-content h2 {
  color: var(--greenColor);
}

.text-container-content p {
  margin: var(--doublePadding) 0 0;
  color: #fff;
}

.text-container-pattern {
  flex-grow: 1;
  background-image: url("../../public/images/tile.png");
  background-repeat: repeat;
  background-size: 240px;
}

/* Mobile */
@media screen and (max-width: 900px) {
  .revive-text-container {
    min-height: 120vh;
  }

  .text-container-content {
    padding: calc(6% + var(--quadPadding)) var(--pageMargin);
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .revive-text-container {
    min-height: 100vh;
  }
}
