@font-face {
  font-family: "AvantGarde";
  src: url("./fonts/AvantGardeLT-Book.ttf");
}

* {
  box-sizing: border-box;
}

:root {
  --pageColor: #7e6c6c;
  --textColor: #1a1423;
  --textColorDisabled: #4d4d4d;
  --headerTextColor: #000;
  --subheaderTextColor: #333;
  --greenColor: #d4d2a5;
  --quadPadding: clamp(2rem, 0.75vw + 4rem, 6rem);
  --doublePadding: clamp(1rem, 0.75vw + 1.25rem, 3rem);
  --standardPadding: clamp(0.5rem, 0.75vw + 1rem, 1.5rem);
  --halfPadding: clamp(0.25rem, 0.37vw + 1rem, 0.75rem);
  --quarterPadding: clamp(0.125rem, 0.17vw + 1rem, 0.375rem);
  --defaultShadow: rgba(34, 34, 34, 0.1);
  --borderColor: #f2f2f2;
  --primaryFont: "AvantGarde", "Helvetica Neue", sans-serif;
  --headerHeight: 80px;
  --footerHeight: 60px;
  --pageMargin: calc(var(--quadPadding) * 2);

  /* Font sizing */

  /* buttons */
  --reviveButton: 1rem;

  /* body, p */
  --reviveBody: clamp(1.15rem, 0.3vw + 1rem, 1.3rem);

  /* subhead */
  --reviveSubhead: clamp(1.063rem, 0.1vw + 1rem, 1.125rem);

  /* h1 */
  --reviveH1: clamp(2.5rem, 1.5vw + 1rem, 2.6rem);

  /* h2 */
  --reviveH2: clamp(2.25rem, 1.5vw + 1rem, 2.3rem);

  /* h3 */
  --reviveH3: clamp(1.5rem, 0.5vw + 1rem, 1.6rem);

  /* h4 */
  --reviveH4: clamp(1.25rem, 0.5vw + 0.8rem, 1.3rem);

  /* h5 */
  --reviveH5: clamp(0.95rem, 0.5vw + 0.5rem, 1.08rem);

  /* h6 */
  --reviveH6: 0.875rem;

  /* End font sizing */
}

html {
  scroll-behavior: smooth;
  width: 100vw;
}

body {
  font-family: var(--primaryFont);
  font-size: 14px;
  font-size: var(--reviveBody);
  font-weight: 400;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100svh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--pageColor);
}

.revive-app {
  position: relative;
  overflow: hidden;
}

.revive-app.white {
  background-color: #fff;
  min-height: 100vh;
  overflow-y: scroll;
}

.revive-app-main {
  transition: all 0.4s ease;
  overflow-y: auto;
  scroll-behavior: smooth;
  height: 100svh;
  position: relative;
}

.revive-app-main.slide,
.revive-app-contact.slide {
  transform: translateX(-25vw);
}

.revive-app-contact {
  width: 25vw;
  transition: all 0.4s ease;
  position: absolute;
  top: 0;
  height: 100%;
  right: -25vw;
}

/* Mobile contact */
@media screen and (max-width: 900px) {
  .revive-app-main.slide,
  .revive-app-contact.slide {
    transform: translateX(-80vw);
  }

  .revive-app-contact {
    width: 80vw;
    right: -80vw;
  }
}

/* Resets */
a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
  padding: 0;
  font-size: var(--reviveBody);
  line-height: var(--reviveH2);
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
textarea,
input {
  text-rendering: inherit;
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  max-width: 100%;
  white-space: nowrap;
  font-size: var(--reviveButton);
}

input:not(:read-only):focus {
  border-color: var(--brandPrimaryColor) !important;
}

button:disabled {
  opacity: 0.4;
  pointer-events: none;
}

button:not(:disabled):hover {
  cursor: pointer;
  opacity: 0.8;
}

/* End resets */

/* Header font sizing */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: 400;
}

h1 {
  font-size: var(--reviveH1);
}

h2 {
  font-size: var(--reviveH2);
  text-decoration: underline;
  text-underline-offset: 5px;
}

h3 {
  font-size: var(--reviveH3);
}

h4 {
  font-size: 0.9rem;
  font-size: clamp(0.9rem, 1.25vw + 1rem, 1.1rem);
  margin: 0 0 var(--standardPadding);
}

h5 {
  font-size: 1rem;
  font-size: clamp(1rem, 1vw + 1rem, 1.22rem);
  margin: 0 0 var(--halfPadding);
}

h6 {
  font-weight: 600;
  font-size: 0.8rem;
  font-size: clamp(0.8rem, 0.5vw + 1rem, 0.95rem);
  margin: 0 0 var(--halfPadding);
}

/* End header font sizing */

/* Font properties */
.no-margin {
  margin: 0;
}

.italic {
  font-style: italic;
}

.secondary-font {
  font-family: var(--secondaryFont);
}

.tertiary-font {
  font-family: var(--tertiaryFont);
}

.color-primary {
  color: var(--brandPrimaryColor);
}

/* Buttons */
.revive-primary-btn {
  border-radius: 8px;
  padding: var(--halfPadding) var(--doublePadding);
  font-size: var(--reviveButton);
  background-color: #fff;
  transition: all 0.4s ease;
}

.revive-primary-btn:hover {
  transform: translateY(-2px);
  opacity: 0.9;
}

.revive-primary-btn.green {
  background-color: var(--greenColor);
  color: var(--textColor);
}

.revive-underline-btn {
  font-family: var(--primaryFont);
  color: var(--brandPrimaryColor);
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 600;
  font-size: var(--reviveButton);
}

.mobile-only {
  display: none !important;
}

/* Error */
.revive-error {
  text-align: center;
  padding: var(--quadPadding) var(--quadPadding) 0;
}

.revive-error p {
  margin: var(--doublePadding) 0;
}

.revive-error a {
  color: var(--brandPrimaryColor);
}

/* Mobile */
@media screen and (max-width: 900px) {
  :root {
    /* h1 */
    --reviveH1: clamp(2rem, 1vw + 1rem, 2.1rem);

    /* h2 */
    --reviveH2: clamp(1.5rem, 1vw + 1rem, 1.6rem);

    /* h3 */
    --reviveH3: clamp(1rem, 0.5vw + 1rem, 1.1rem);

    /* h4 */
    --reviveH4: clamp(0.95rem, 0.2vw + 1rem, 1rem);

    /* h5 */
    --reviveH5: clamp(0.95rem, 0.5vw + 0.6rem, 1rem);

    /* Margins */
    --quadPadding: 1.25rem;
    --doublePadding: 0.75rem;
    --standardPadding: 0.33rem;
    --quarterPadding: 0.15rem;
    --pageMargin: 1.5rem;

    /* Header */
    --headerHeight: 60px;
  }

  p {
    line-height: 2.2rem;
  }

  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
  }

  .revive-primary-btn {
    white-space: nowrap;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  :root {
    /* body, p */
    --reviveBody: clamp(0.7rem, 0.3vw + 1rem, 0.75rem);
  }

  p {
    line-height: 1.3rem;
  }
}
