.revive-contact {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: var(--doublePadding);
  display: flex;
  flex-direction: column;
  height: 100svh;
  position: relative;
}

.revive-toast {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--standardPadding);
  font-size: 1rem;
}

.revive-toast.success {
  background-color: rgb(13, 228, 142);
}

.revive-toast.error {
  background-color: rgb(255, 0, 89);
  color: #fff;
}

.revive-contact-actions {
  margin: auto 0 0;
  display: flex;
  align-items: center;
}

.revive-contact-actions button:last-of-type {
  margin: 0 0 0 auto;
}

.revive-contact-input {
  display: flex;
  gap: var(--standardPadding);
  align-items: center;
  padding: var(--halfPadding) 0;
  border-bottom: 1px solid #eee;
}

.revive-contact-input:last-of-type {
  border: none;
}

.revive-contact-input h6 {
  margin: 0;
  padding: 0;
}

.revive-contact-input h6:first-of-type {
  color: #999;
}

.revive-contact-input input {
  font-size: var(--reviveH6);
  font-weight: 600;
  color: var(--textColor);
  margin: 0;
  padding: 0;
  width: 100%;
}

.revive-contact-input textarea {
  color: var(--textColor);
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: var(--reviveH6);
  font-weight: 600;
}
