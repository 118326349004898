.revive-process {
  background-color: var(--greenColor);
  padding: var(--quadPadding) var(--pageMargin);
  display: flex;
  align-items: center;
  gap: var(--quadPadding);
}

.process-info {
  flex: initial;
  width: 50%;
}

.process-info p {
  margin: var(--doublePadding) 0 0;
}

.process-grid {
  flex: initial;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(2, 1fr);
  perspective: 500px;
  column-gap: var(--doublePadding);
}

.process-grid li {
  background-color: #fff;
  padding: var(--doublePadding);
  display: flex;
  gap: var(--halfPadding);
  flex-direction: column;
}

.process-grid li svg {
  width: 3vmax;
  height: 3vmax;
  overflow: visible;
}

.process-grid li h3 {
  font-weight: 700;
  font-size: var(--reviveH3);
}

.process-grid li p {
  font-size: var(--reviveH5);
  line-height: var(--reviveH3);
}

.process-grid li:nth-child(2n) {
  margin: var(--doublePadding) 0 0;
}

.process-grid li:nth-child(2n - 1) {
  margin: 0 0 var(--doublePadding);
}

/* Mobile */
@media screen and (max-width: 1440px) {
  .process-grid {
    row-gap: var(--doublePadding);
  }

  .process-grid li {
    margin: 0 !important;
  }
}

@media screen and (max-width: 900px) {
  .revive-process {
    flex-direction: column;
  }

  .process-info,
  .process-grid {
    width: 100%;
  }

  .process-grid {
    margin: 6% 0;
  }

  .process-grid li {
    width: 100%;
    margin: var(--doublePadding) 0 0 !important;
    min-height: 35vh;
    justify-content: center;
    padding: var(--doublePadding) var(--quadPadding);
  }

  .process-grid li svg {
    width: 3rem;
    height: 3rem;
  }

  .revive-process {
    gap: 2rem;
  }

  .process-info p {
    margin: var(--quadPadding) 0 0;
  }

  .process-grid li p {
    line-height: var(--reviveH2);
  }
}

@media screen and (max-width: 900px) and (orientation: portrait) {
  .process-grid {
    grid-template-columns: repeat(auto-fit, minmax(44vw, 1fr));
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .process-grid {
    grid-template-columns: repeat(auto-fit, minmax(40vw, 1fr));
  }

  .process-grid li {
    min-height: 75vh;
  }
}

/* Large screen */
@media screen and (min-width: 2000px) {
  .process-grid {
    grid-template-columns: repeat(auto-fit, minmax(13vw, 1fr));
  }
}
