.revive-quote {
  padding: var(--quadPadding) var(--pageMargin);
  display: flex;
  gap: var(--quadPadding);
  align-items: center;
  justify-content: flex-start;
}

.revive-quote-person {
  width: 30%;
  flex: initial;
  color: var(--greenColor);
  display: flex;
  flex-direction: column;
  gap: var(--halfPadding);
}

.revive-quote-person img {
  width: 50%;
}

.revive-quote-person-info {
  margin: 0 0 0 25%;
  transform: translateX(-25%);
}

.revive-quote-person-info h3 {
  font-weight: 700;
}

.revive-quote-block {
  color: #fff;
  width: 60%;
  flex: initial;
  position: relative;
}

.revive-left-quote {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-140%, -90%);
}

.revive-right-quote {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0, 90%);
}

/* Mobile */
@media screen and (max-width: 900px) {
  .revive-quote {
    flex-direction: column-reverse;
    justify-content: center;
    padding: calc(6% + var(--pageMargin)) var(--pageMargin);
  }

  .revive-quote-person {
    width: 90%;
    align-items: center;
  }

  .revive-quote-block {
    width: 90%;
    margin: var(--pageMargin) 0 0;
  }

  .revive-right-quote svg,
  .revive-left-quote svg {
    height: 1rem;
    width: 1rem;
  }

  .revive-quote-person-info {
    margin: 0;
    transform: none;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .revive-quote-person img {
    width: 5rem;
  }
}
