footer {
  height: var(--footerHeight);
  color: var(--greenColor);
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0 var(--quadPadding);
  font-weight: 700;
}

.footer-design {
  margin: 0 0 0 auto;
}

.footer-design a {
  text-decoration: underline;
}

/* Mobile */
@media screen and (max-width: 900px) {
  footer {
    font-size: 0.55rem;
    padding: 0 var(--doublePadding);
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  footer {
    font-size: 0.8rem;
    padding: 0 var(--quadPadding);
  }
}
