.revive-calendar {
  padding: var(--quadPadding) var(--pageMargin);
  background-color: #fff;
}

.revive-calendar .cal-inline-container {
  width: 100%;
  margin: var(--quadPadding) 0 0;
}

/* Mobile */
@media screen and (max-width: 900px) {
  .revive-calendar {
    padding: calc(6% + var(--quadPadding)) var(--pageMargin);
  }
}
